import { Client, StudyLinksQueryArgs, StudyLinkType } from '@curvo/apollo'
import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import Cognito from '../../../../../config/Cognito'
import { AgGridRefProps } from './common'
import { StudyLinkCache } from './StudyLinkCache'

const ROWS_PER_LOAD = 1000
const MAX_CONNECTION = 8

type LoadStudyResult = {
  progress?: number
  error?: Error
  loading: boolean
}

export function useLoadStudyLinks(
  studyId: number,
  gridRef: React.RefObject<AgGridRefProps>,
  queryArgs: StudyLinksQueryArgs,
): LoadStudyResult {
  const cachedData = StudyLinkCache.studies.get(studyId)
  const [studyLinks, setStudyLinks] = useState<StudyLinkType[]>([])
  const [total, setTotal] = useState(-1)
  const [error, setError] = useState<Error>()
  const [availableConnection, setAvailableConnection] = useState(1)
  const [loadOffset, setLoadOffset] = useState(0)

  const memoQueryArgs = useRef<StudyLinksQueryArgs>()

  useEffect(() => {
    if (gridRef.current) {
      gridRef.current.api.setRowData([])
      gridRef.current.api.showLoadingOverlay()
    }

    if (memoQueryArgs.current !== queryArgs) {
      memoQueryArgs.current = queryArgs
      StudyLinkCache.studies.delete(studyId)
    }

    setTotal(-1)
    setStudyLinks([])
    setAvailableConnection(1)
    setLoadOffset(0)
  }, [studyId, queryArgs, gridRef])

  useEffect(() => {
    const getToken = async () => {
      const session = await Cognito.getSession()
      return session.getAccessToken().getJwtToken()
    }

    const loadTransactions = () => {
      setAvailableConnection(old => old - 1)
      if (loadOffset < total) {
        doFetchStudyLinksData(memoQueryArgs.current!, ROWS_PER_LOAD, loadOffset).then(() => {
          setAvailableConnection(old => old + 1)
          if (loadOffset >= total) {
            gridRef.current?.api.hideOverlay()
          }
        })
      }
    }

    const doGetStudyLinksCount = async (args: StudyLinksQueryArgs) => {
      setAvailableConnection(0)
      const baseUrl = Client.getUrl()
      try {
        const token = await getToken()
        const result = await axios.get(`${window.location.protocol}//${baseUrl}/study/${studyId}/links-count`, {
          params: args,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })

        if (args === memoQueryArgs.current) {
          setTotal(result.data.count)
          if (result.data.count === 0) {
            gridRef.current?.api.showNoRowsOverlay()
          }
        }
        setAvailableConnection(MAX_CONNECTION)
        return result.data.count
      } catch (e) {
        setError(e)
      }
    }

    const doFetchStudyLinksData = async (args: StudyLinksQueryArgs, first: number, skip: number) => {
      setLoadOffset(offset => offset + first)
      const baseUrl = Client.getUrl()
      try {
        const token = await getToken()
        const result = await axios.get(`${window.location.protocol}//${baseUrl}/study/${studyId}/links`, {
          params: {
            ...args,
            first,
            skip,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })

        if (
          gridRef.current &&
          // args.catalog === gridRef.current.catalog &&
          // args.state === gridRef.current.step
          args === memoQueryArgs.current
        ) {
          setStudyLinks(old => [...old, ...result.data.data])
          StudyLinkCache.addToStudy(result.data.data, studyId, memoQueryArgs.current!)

          gridRef.current.api.applyTransaction({
            add: result.data.data,
          })
        }
      } catch (e) {
        setError(e)
      }
    }

    // if study data is not loaded
    if (total === -1 || studyLinks.length < total) {
      // if we have cached data (size > 0, and have data === total, and cached queryArgs === current query args)
      if (
        cachedData &&
        cachedData.size > 0 &&
        cachedData.size === total &&
        StudyLinkCache.savedQueryArgs.get(studyId) === memoQueryArgs.current
      ) {
        const cachedRows = [...cachedData.values()]
        setStudyLinks(cachedRows)
        setTotal(cachedData.size)
        if (gridRef.current) {
          gridRef.current.api.setRowData(cachedRows)
        }
      } else if (total === -1) {
        if (availableConnection > 0) {
          doGetStudyLinksCount(memoQueryArgs.current!)
        }
      } else if (availableConnection > 0) {
        loadTransactions()
      }
    }
  }, [studyId, total, loadOffset, availableConnection, studyLinks.length, cachedData, queryArgs, gridRef])

  return {
    progress: Math.round(studyLinks && total ? ((studyLinks.length * 1.0) / total) * 100 : 0),
    error,
    loading: total === -1 || studyLinks.length < total,
  }
}
