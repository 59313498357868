import { PartsForSelectionData } from '@curvo/apollo'
import LoadingIndicator from '@curvo/common-ui/dist/components/Loading/Spinner'
import Select, { LabeledValue, SelectProps } from 'antd/lib/select'
import { debounce } from 'lodash'
import React, { useEffect, useState } from 'react'
import { StyledSelect, StyledSelectPropsType } from './common'

const { Option } = Select

export const PartSelect: React.FC<SelectProps<LabeledValue> & StyledSelectPropsType & { manufacturerId: string }> = ({
  manufacturerId,
  ...props
}) => {
  const [searchText, setSearchText] = useState('')

  useEffect(() => {
    if (props.value) {
      setSearchText(props.value.label as string)
    }
  }, [props.value])

  return (
    <PartsForSelectionData variables={{ searchText, args: { manufacturerId } }} fetchPolicy="network-only">
      {({ data, loading }) => (
        <StyledSelect
          onSearch={debounce(setSearchText, 800)}
          loading={loading}
          showSearch
          labelInValue
          filterOption={false}
          showArrow={false}
          defaultActiveFirstOption={false}
          placeholder="Select part"
          notFoundContent={loading ? <LoadingIndicator /> : 'No Data'}
          {...props}>
          {data && data.parts && data.parts.edges.map(edge => <Option key={edge.node.id}>{edge.node.partName}</Option>)}
        </StyledSelect>
      )}
    </PartsForSelectionData>
  )
}
