import React, { useContext } from 'react'
import Fullstory, { identify } from 'react-fullstory'
import { MeContext } from '@curvo/apollo'
import env from '../config/env'

export const Tracking: React.FC = () => {
  const FS_ORG_ID = 'UKb'
  const FS_NAMESPACE = 'FS'

  const { data: meData } = useContext(MeContext)

  if (meData?.me.id && env.env && env.env !== 'dev') {
    identify(meData.me.id, {
      displayName: `${meData.me.firstName} ${meData.me.lastName}`,
      email: meData.me.email,
    })
  }

  return <Fullstory org={FS_ORG_ID} namespace={FS_NAMESPACE} />
}
