import React from 'react'
import { Route, Routes } from 'react-router'
import { CaseReview } from './CaseReview'
import { Grooming2 } from './Grooming2/Grooming2'
import { StudyTable } from './Study'
import { SuppliersGroom } from './SuppliersGroom'
import { StudyTransactionGroom } from './TransactionGroom'

export const DataCleaning: React.FC = () => {
  return (
    <Routes>
      <Route path={'/:studyId/transaction'} element={<StudyTransactionGroom />} />
      <Route path={'/:studyId/case-review'} element={<CaseReview />} />
      <Route path={'/:studyId/ts-id-links'} element={<Grooming2 />} />
      <Route path={'/:studyId/norm-suppliers'} element={<SuppliersGroom />} />
      <Route path={'/'} element={<StudyTable />} />
    </Routes>
  )
}
