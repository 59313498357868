import React, { useState, useImperativeHandle, useEffect } from 'react'
import { NormalizedSupplier, Manufacturer } from '@curvo/apollo'
import { LabeledValue } from 'antd/lib/select'
import { MultipleManufacturerSelect } from '../../Update/components/Select/ManufacturerSelect'
import { AgWrapperPropsType } from './ManufacturerSelectAgWrapper'

export const MultipleManufacturerSelectAgWrapperNormalizedSupplier = React.forwardRef<
  {},
  AgWrapperPropsType<NormalizedSupplier>
>((params, ref) => {
  const [labeledValue, setLabeledValue] = useState<LabeledValue[] | null>()
  const { value: retries, api } = params
  useImperativeHandle(ref, () => ({
    getValue: () => labeledValue,
  }))

  const value: LabeledValue[] | undefined =
    (retries &&
      retries.map((retry: Pick<Manufacturer, 'id' | 'name'>) => ({
        key: retry.id,
        label: retry.name,
      }))) ||
    undefined

  useEffect(() => {
    if (labeledValue !== undefined) {
      api.stopEditing()
    }
  }, [labeledValue, api])

  return (
    <MultipleManufacturerSelect
      value={value}
      allowClear
      onChange={v => {
        if (v !== undefined) {
          setLabeledValue(v as LabeledValue[])
        } else {
          setLabeledValue(null)
        }
      }}
    />
  )
})
