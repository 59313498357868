import {
  ColumnState,
  DragStoppedEvent,
  FillEndEvent,
  GetContextMenuItemsParams,
  GridReadyEvent,
  IHeaderParams,
  MenuItemDef,
  RowClickedEvent,
  RowNode,
  ValueFormatterParams,
} from '@ag-grid-community/core'
import { FillOperationParams } from '@ag-grid-community/core/dist/es6/entities/gridOptions'
import { AgGridColumnProps } from '@ag-grid-community/react'
import { AgGridReact } from '@ag-grid-community/react/lib/agGridReact'
import { AllModules, MultiFilterModule, SetFilterModule } from '@ag-grid-enterprise/all-modules'
import {
  BaseUpdateStudyLink,
  BulkUpdateStudyLinkInput,
  MeWithoutPaymentContext,
  Role,
  STUDIES_QUERY,
  StudyLinkCatalogEnum,
  StudyLinkType,
  StudyLinksQueryArgs,
  StudyTransactionState,
  commitStudy,
  useGetStudyLinkMasterConflicts,
  useStudyData,
} from '@curvo/apollo'
import { Button, Icon, PageHeader, Progress, Select, message } from 'antd'
import { isNil, pick, range } from 'lodash'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import styled from 'styled-components'
import { caseInsensitiveComparator } from '../../../../components/common'
import { ControlsWrapper } from '../TransactionGroom'
import { currencyFormatter } from '../common'
import { GICAgWrapper } from '../components/GICAgWrapper'
import { AllowedGicTypeOneAgWrapper } from '../components/GicTypeOneAgWrapper'
import { AllowedGicTypeTwoAgWrapper } from '../components/GicTypeTwoAgWrapper'
import { GroomingMatchedRatioAgComponent } from '../components/GroomingMatchedRatioAgComponent'
import { ManufacturerSelectAgWrapperStudyLink } from '../components/ManufacturerSelectAgWrapper'
import { ManufacturerTypeSelect } from '../components/ManufacturerTypeSelect'
import { MasterDataSelectorAgWrapper } from '../components/MasterDataSelector'
import { MaterialSelectAgWrapper } from '../components/MaterialSelectAgWrapper'
import { NormalizedSupplierSelectAgWrapper } from '../components/NormalizedSupplierSelectAgWrapper'
import { PartSelectStudyLinkAgWrapper } from '../components/PartSelectAgWrapper'
import { SegmentSelectAgWrapper } from '../components/SegmentSelectAgWrapper'
import { StudyTransactionStateFilter } from '../components/StudyTransactionStateFilter'
import * as Buttons from './components/Buttons'
import {
  TwoSideFullDiv,
  bulkUpdateStudyLinks,
  doBulkMoveToTwinMatch,
  doRematchStudyLinks,
  getSelectedRows,
} from './components/Buttons'
import { AgCrossMatchingRenderer } from './components/CrossMatching'
import { useLoadStudyLinks } from './components/LoadStudyLinksHook'
import { MovedFromAIFilter } from './components/MovedFromAIFilter'
import { ScoreBoard } from './components/ScoreBoard'
import { StudyLinkMasterGrid } from './components/StudyLinkMasterGrid'
import {
  AgGridRefProps,
  Grooming2Context,
  Grooming2ContextProps,
  STUDY_TRANSACTION_STATE_OPTION_MAPPING,
  getMatchedSimilarityScore,
  getStudyTransactionStateKey,
  matchedManufacturerValueSetter,
  matchedPartValueSetter,
} from './components/common'
import { useLastActionTrack } from './components/useLastActionHook'

const GROOMING_2_COLUMN_STATE_PROP = 'grooming2ColumnsState'

const DEFAULT_CATALOG = StudyLinkCatalogEnum.Stan

export const Grooming2: React.FC = () => {
  const routeParams = useParams()
  const navigate = useNavigate()
  const studyId = parseInt(routeParams.studyId!, 10)

  const [queryArgs, setQueryArgs] = useState<StudyLinksQueryArgs>({
    studyId,
    state: StudyTransactionState.SupplierMatching,
    catalog: DEFAULT_CATALOG,
  })

  const gridRef = useRef<AgGridRefProps>(null)
  const lastActionHook = useLastActionTrack(gridRef)

  const studyDataResult = useStudyData({ id: studyId })
  const study = studyDataResult.data && studyDataResult.data.study

  const { error, progress, loading } = useLoadStudyLinks(studyId, gridRef, queryArgs)

  const meData = useContext(MeWithoutPaymentContext)
  const isDataEnrichmentAdmin = useMemo(() => meData?.data?.role === Role.DataEnrichmentAdmin, [meData])

  // for conflicts checker
  const [selectedRow, setSelectedRow] = useState<StudyLinkType>()

  const { data: masterConflictsData } = useGetStudyLinkMasterConflicts({
    variables: {
      studyId,
      tsId: selectedRow?.tsId || '',
      catalog: queryArgs.catalog || DEFAULT_CATALOG,
    },
    skip: !selectedRow,
  })

  const masterConflicts = masterConflictsData?.studyLinkConflicts || []
  useEffect(() => {
    if (error) {
      message.error(error.message)
    }
  }, [error])

  const pageHeaderExtras = [
    <Select
      style={{ width: 150 }}
      value={queryArgs.catalog || undefined}
      onChange={(v: StudyLinkCatalogEnum) => setQueryArgs(old => ({ ...old, catalog: v }))}>
      <Select.Option key="stan" value="stan">
        Stan
      </Select.Option>
      <Select.Option key="medline" value="medline">
        Commodities
      </Select.Option>
      <Select.Option key="gudid" value="gudid">
        GudID
      </Select.Option>
    </Select>,
    <Select
      style={{ width: 150 }}
      value={getStudyTransactionStateKey(queryArgs.state) || 'HI'}
      onChange={(v: string) => {
        const value = STUDY_TRANSACTION_STATE_OPTION_MAPPING[v].value
        setQueryArgs(old => ({ ...old, state: value }))
        if (gridRef.current) {
          gridRef.current.step = value
        }
      }}>
      {Object.keys(STUDY_TRANSACTION_STATE_OPTION_MAPPING).map(key => (
        <Select.Option key={key} value={key}>
          {STUDY_TRANSACTION_STATE_OPTION_MAPPING[key].label}
        </Select.Option>
      ))}
    </Select>,
    <ManufacturerTypeSelect
      style={{ width: 150 }}
      allowClear
      value={queryArgs.manufacturerType || undefined}
      onChange={v => {
        setQueryArgs({ ...queryArgs, manufacturerType: v })
      }}
      placeholder="Select Type"
    />,
    queryArgs.state === StudyTransactionState.FinalReview ? (
      <Button
        type="primary"
        onClick={() => {
          commitStudy({ id: studyId, copyGrooming2: true }, { refetchQueries: [{ query: STUDIES_QUERY }] })
          navigate('/data-cleaning/')
        }}>
        Send to Case Review
      </Button>
    ) : null,
  ]

  const pageTitle = `Study ${studyId}`

  const pageSubTitle = study && (
    <PageHeaderSubtitleWrapper>
      <span>{`Facility ID: ${study.facilityId}, Facility: ${study.facilityName}`}</span>
      <ScoreBoard level={study.level || 1} score={study.score || 0} percentage={study.groomedPercentage || 0} />
    </PageHeaderSubtitleWrapper>
  )

  const grooming2Context: Grooming2ContextProps = useMemo(
    () => ({
      studyId,
      gridRef,
      catalog: queryArgs.catalog || DEFAULT_CATALOG,
      lastActionStackHook: lastActionHook,
      step: queryArgs.state || undefined,
    }),
    [studyId, lastActionHook, queryArgs.catalog, queryArgs.state],
  )

  useEffect(() => {
    if (gridRef.current) {
      const api = gridRef.current.api
      if (api && queryArgs.catalog) {
        api.destroyFilter('matchedPart')
      }
    }
  }, [queryArgs.catalog])

  useEffect(() => {
    if (gridRef.current) {
      const api = gridRef.current.api
      if (queryArgs.catalog) {
        api.setColumnDefs(genColumns(grooming2Context))
      }
      if (queryArgs.state) {
        const stateFilter: StudyTransactionStateFilter = api.getFilterInstance('state') as any
        if (
          queryArgs.state === StudyTransactionState.Conflict ||
          queryArgs.state === StudyTransactionState.SupplierMatchingAndFinalReview
        ) {
          stateFilter.setModel({ state: null })
        } else {
          stateFilter.setModel({ state: queryArgs.state })
        }

        api.onFilterChanged()
      }
    }
  }, [queryArgs.catalog, queryArgs.studyId, queryArgs.state, grooming2Context])

  if (gridRef.current) {
    gridRef.current.catalog = queryArgs.catalog || undefined
    gridRef.current.step = queryArgs.state || undefined
  }

  const statusBar = useMemo(
    () => ({
      statusPanels: [
        {
          statusPanelFramework: GroomingMatchedRatioAgComponent,
          align: 'right',
          statusPanelParams: {
            isMatchedCb: (v: StudyLinkType) =>
              gridRef.current
                ? (gridRef.current.catalog === StudyLinkCatalogEnum.Stan
                    ? v.matchStanId
                    : gridRef.current?.catalog === StudyLinkCatalogEnum.Medline
                    ? v.matchMedId
                    : v.matchGudidId) !== null
                : false,
          },
        },
      ],
    }),
    [],
  )

  return (
    <Grooming2Context.Provider value={{ props: grooming2Context }}>
      <div style={{ height: '100%', flexDirection: 'column', display: 'flex' }}>
        <PageHeader
          title={pageTitle}
          subTitle={pageSubTitle}
          onBack={() => navigate('/data-cleaning/')}
          extra={pageHeaderExtras}
        />
        <div
          style={{
            marginLeft: '24px',
            marginRight: '24px',
            display: 'flex',
            flexDirection: 'column',
            flex: '1 1 auto',
          }}>
          {queryArgs.state === StudyTransactionState.FinalReview && <Buttons.ApprovedTopButtons studyId={studyId} />}
          {isSupplierState(queryArgs.state) && (
            <Buttons.HIMatchTopButtons
              state={queryArgs.state}
              toShow={queryArgs.state === StudyTransactionState.Hidden}
            />
          )}
          {queryArgs.state === StudyTransactionState.IgnoreRow && (
            <TwoSideFullDiv>
              <div />
              <Buttons.UnIgnoreRowButton />
            </TwoSideFullDiv>
          )}
          <div className="ag-theme-balham" style={{ flex: 1, minHeight: '200px', width: 'fill-parent' }}>
            {loading && <Progress percent={progress} />}
            <AgGridReact
              ref={gridRef}
              detailRowAutoHeight={true}
              onRowClicked={(e: RowClickedEvent) => {
                if (gridRef.current?.step === StudyTransactionState.Conflict) {
                  setSelectedRow(e.data)
                }
              }}
              suppressRowClickSelection
              columnDefs={genColumns(grooming2Context)}
              masterDetail={true}
              enableFillHandle={true}
              detailCellRendererFramework={AgCrossMatchingRenderer}
              detailCellRendererParams={{ studyId }}
              getContextMenuItems={(params: GetContextMenuItemsParams) => [
                ...getContextMenuItems({ ...grooming2Context, isDataEnrichmentAdmin })(params),
                'copy',
              ]}
              defaultColDef={{
                sortable: true,
                filter: true,
                resizable: true,
                headerCheckboxSelectionFilteredOnly: true,
                headerCheckboxSelection: isFirstColumn,
                checkboxSelection: isFirstColumn,
                comparator: caseInsensitiveComparator,
              }}
              enableRangeSelection={true}
              onDragStarted={() => {
                if (gridRef.current) {
                  gridRef.current.onRangeFilling = true
                }
              }}
              onFillEnd={(e: FillEndEvent) => {
                handleOnFillEnd(grooming2Context)(e)
                if (gridRef.current) {
                  gridRef.current.onRangeFilling = false
                }
              }}
              fillOperation={(params: FillOperationParams) => {
                if (typeof params.initialValues[0] === 'string') {
                  return params.initialValues[0]
                }
                return false
              }}
              modules={[...AllModules, SetFilterModule, MultiFilterModule]}
              rowSelection="multiple"
              rowGroupPanelShow="always"
              groupSelectsChildren={true}
              getRowNodeId={(node: StudyLinkType) => node.id}
              onDragStopped={(e: DragStoppedEvent) => {
                const columnState = JSON.stringify(
                  e.columnApi.getColumnState().map(colState => pick(colState, ['colId', 'width', 'hide', 'pinned'])),
                )
                if (gridRef.current) {
                  gridRef.current.onRangeFilling = false
                }
                localStorage.setItem(`${GROOMING_2_COLUMN_STATE_PROP}_${queryArgs.catalog!}`, columnState)
              }}
              onGridReady={({ api, columnApi }: GridReadyEvent) => {
                const columnStateStr = localStorage.getItem(`${GROOMING_2_COLUMN_STATE_PROP}_${queryArgs.catalog!}`)
                if (columnStateStr) {
                  const savedColumnStates: ColumnState[] = JSON.parse(columnStateStr)
                  const currentColumnStates = columnApi.getColumnState()
                  const unsavedColumns = currentColumnStates.filter(
                    col => !savedColumnStates.find(savedCol => savedCol.colId === col.colId),
                  )

                  columnApi.setColumnState([...savedColumnStates, ...unsavedColumns])
                  columnApi.setColumnVisible('state', false)

                  columnApi.moveColumn('index', 0)
                }
                const stateFilter: StudyTransactionStateFilter = api.getFilterInstance('state') as any

                stateFilter.setModel({ state: queryArgs.state! })
                api.onFilterChanged()
              }}
              autoGroupColumntDef={{
                headerCheckboxSelectionFilteredOnly: true,
                headerName: 'Group',
                cellRendererParams: {
                  checkbox: true,
                },
                comparator: (_valueA: any, _valueB: any, nodeA: RowNode, nodeB: RowNode) => {
                  return (
                    ((nodeA.childrenAfterFilter && nodeA.childrenAfterFilter.length) || 0) -
                    ((nodeB.childrenAfterFilter && nodeB.childrenAfterFilter.length) || 0)
                  )
                },
              }}
              enableGroupEdit={true}
              statusBar={statusBar}
              frameworkComponents={{
                MovedFromAIFilter,
              }}
              overlayNoRowsTemplate={'No Rows To Show'}
            />
          </div>
          {queryArgs.state === StudyTransactionState.Conflict && !!selectedRow && (
            <div className="ag-theme-balham" style={{ height: '35vh', width: 'fill-parent', marginTop: 16 }}>
              <h1>Master Conflicts</h1>

              <StudyLinkMasterGrid masterConflicts={masterConflicts} catalog={queryArgs.catalog || DEFAULT_CATALOG} />
            </div>
          )}
          <TwoSideFullDiv>
            <ControlsWrapper>
              {isSupplierState(queryArgs.state) && <Buttons.SupplierMatchingButtons />}
              {queryArgs.state === StudyTransactionState.IgnoreSupplier && <Buttons.IgnoreSupplierButtons />}
              {queryArgs.state === StudyTransactionState.BlacklistSupplier && <Buttons.BlacklistSupplierButtons />}
              {queryArgs.state === StudyTransactionState.BlacklistStrippedItem && (
                <Buttons.BlacklistStrippedItemButtons />
              )}
              {(queryArgs.state === StudyTransactionState.FinalReview ||
                queryArgs.state === StudyTransactionState.SupplierMatchingAndFinalReview) && (
                <Buttons.ApprovedItemButtons />
              )}
              {queryArgs.state === StudyTransactionState.TwinApproved && <Buttons.TwinApprovedItemButtons />}
              {queryArgs.state === StudyTransactionState.TwinMatch && <Buttons.TwinMatchButtons />}
            </ControlsWrapper>
          </TwoSideFullDiv>
        </div>
      </div>
    </Grooming2Context.Provider>
  )
}

const genColumns = (props: Grooming2ContextProps): AgGridColumnProps[] => {
  const { catalog, studyId, gridRef } = props
  const stanColumns = [
    {
      headerName: 'Gic',
      field: 'gicName',
      colId: 'gic',
      editable: true,
      cellEditorFramework: GICAgWrapper,
      valueSetter: ({ data, node, newValue }) => {
        if (node?.group) {
          message.error('You can only edit gic for one transaction at a time')
          return false
        }
        if (gridRef.current?.onRangeFilling) {
          return false
        }
        if (newValue !== undefined) {
          Buttons.bulkUpdateStudyLinks(
            {
              studyId,
              tsIds: [data.tsId],
              update: newValue
                ? {
                    gicId: newValue?.id,
                  }
                : {
                    gicId: null,
                  },
            },
            gridRef?.current?.api,
          )
            .then(() => message.success('updated'))
            .catch(e => message.error(e.message))

          return true
        }
        return false
      },
    },
    {
      headerName: 'Type One',
      colId: 'typeOne',
      field: 'typeOneName',
      editable: true,
      cellEditorFramework: AllowedGicTypeOneAgWrapper,
      valueSetter: ({ data, node, newValue }) => {
        if (node?.group) {
          message.error('You can only edit gic type one for one transaction at a time')
          return false
        }
        if (gridRef.current?.onRangeFilling) {
          return false
        }
        if (newValue && newValue.id) {
          Buttons.bulkUpdateStudyLinks(
            {
              studyId,
              tsIds: [data.tsId],
              update: newValue
                ? {
                    typeOneId: newValue?.id,
                  }
                : {
                    typeOneId: null,
                  },
            },
            gridRef?.current?.api,
          )
            .then(() => message.success('updated'))
            .catch(e => message.error(e.message))
          return true
        }
        return false
      },
    },
    {
      headerName: 'Type Two',
      field: 'typeTwoName',
      colId: 'typeTwo',
      editable: true,
      cellEditorFramework: AllowedGicTypeTwoAgWrapper,
      valueSetter: ({ data, node, newValue }) => {
        if (node?.group) {
          message.error('You can only edit gic type two for one transaction at a time')
          return false
        }
        if (gridRef.current?.onRangeFilling) {
          return false
        }
        if (newValue && newValue.id) {
          Buttons.bulkUpdateStudyLinks(
            {
              studyId,
              tsIds: [data.tsId],
              update: newValue
                ? {
                    typeTwoId: newValue?.id,
                  }
                : {
                    typeTwoId: null,
                  },
            },
            gridRef?.current?.api,
          )
            .then(() => message.success('updated'))
            .catch(e => message.error(e.message))
          return true
        }
        return false
      },
    },
    {
      headerName: 'Segment',
      field: 'segmentName',
      colId: 'segment',
      editable: true,
      cellEditorFramework: SegmentSelectAgWrapper,
      valueSetter: ({ data, node, newValue }) => {
        if (node?.group) {
          message.error('You can only edit segment for one transaction at a time')
          return false
        }
        if (gridRef.current?.onRangeFilling) {
          return false
        }
        if (newValue && newValue.id) {
          Buttons.bulkUpdateStudyLinks(
            {
              studyId,
              tsIds: [data.tsId],
              update: newValue && {
                segmentId: newValue?.id,
              },
            },
            gridRef?.current?.api,
          )
            .then(() => message.success('updated'))
            .catch(e => message.error(e.message))
          return true
        }
        return false
      },
    },
    {
      headerName: 'Material',
      field: 'materialName',
      colId: 'material',
      editable: true,
      cellEditorFramework: MaterialSelectAgWrapper,
      valueSetter: ({ data, node, newValue }) => {
        if (node?.group) {
          message.error('You can only edit material for one transaction at a time')
          return false
        }
        if (gridRef.current?.onRangeFilling) {
          return false
        }
        if (newValue && newValue.id) {
          Buttons.bulkUpdateStudyLinks(
            {
              studyId,
              tsIds: [data.tsId],
              update: newValue && {
                materialId: newValue?.id,
              },
            },
            gridRef?.current?.api,
          )
            .then(() => message.success('updated'))
            .catch(e => message.error(e.message))
          return true
        }
        return false
      },
    },
  ]
  return [
    {
      colId: 'index',
      cellRenderer: 'agGroupCellRenderer',
      headerName: '#',
      valueGetter: ({ node }) => (!isNil(node?.rowIndex) ? node!.rowIndex + 1 : null),
      width: 80,
    },
    {
      colId: 'Sim',
      headerName: 'Sim score',
      type: 'numericColumn',
      valueGetter: ({ data }) => getMatchedSimilarityScore(data, catalog),

      width: 130,
    },
    {
      colId: 'match',
      headerName: 'Match',
      valueGetter: ({ data: rawData, node }) => {
        if (node?.group) {
          return undefined
        }
        const data: StudyLinkType = rawData

        if (!data) {
          return undefined
        }
        return catalog === StudyLinkCatalogEnum.Stan
          ? data.stanMatch
          : catalog === StudyLinkCatalogEnum.Medline
          ? data.medMatch
          : data.gudidMatch
      },
      width: 80,
      cellRendererFramework: MatchingCheckAgRenderer,
      valueFormatter: ({ value }: ValueFormatterParams) => {
        return `${translateMatchToNumber(value)} - ${value}`
      },
      filterParams: {
        comparator: matchStateFilterComparator,
      },
    },
    {
      headerName: 'Matched Supplier',
      colId: 'matchSupplier',
      editable: true,

      cellEditorFramework:
        catalog === StudyLinkCatalogEnum.Stan
          ? ManufacturerSelectAgWrapperStudyLink
          : NormalizedSupplierSelectAgWrapper,
      // cellEditorParams: catalog === StudyLinkCatalogEnum.Stan ? {} : {},
      valueGetter: ({ data }) => getManufacturerString(data, catalog),
      valueSetter: matchedManufacturerValueSetter(catalog, studyId, gridRef?.current?.api, gridRef),
      enableRowGroup: true,
      showRowGroup: false,
    },
    ...(catalog === StudyLinkCatalogEnum.Gudid
      ? [
          {
            colId: 'versionModelNumber',
            headerName: 'Version Model Number',
            field: 'gudidVersionModelNumber',

            editable: true,
            cellEditorFramework: MasterDataSelectorAgWrapper,
            cellEditorParams: { catalog, isSecondaryField: true },
            valueSetter: matchedPartValueSetter({
              ...props,
              gridApi: gridRef?.current?.api,
              gridRef,
            }),
          },
        ]
      : []),
    ...(catalog === StudyLinkCatalogEnum.Medline
      ? [
          {
            colId: 'materialCode',
            headerName: 'Material Code',
            field: 'medMaterialCode',

            editable: true,
            cellEditorFramework: MasterDataSelectorAgWrapper,
            cellEditorParams: { catalog, isSecondaryField: true },
            valueSetter: matchedPartValueSetter({
              ...props,
              gridApi: gridRef?.current?.api,
              gridRef,
            }),
          },
        ]
      : []),
    {
      colId: 'matchedPart',
      headerName:
        catalog === StudyLinkCatalogEnum.Stan
          ? 'Matched Part'
          : catalog === StudyLinkCatalogEnum.Gudid
          ? 'Catalog Number'
          : 'SKU',
      editable: true,

      cellEditorFramework:
        catalog === StudyLinkCatalogEnum.Stan ? PartSelectStudyLinkAgWrapper : MasterDataSelectorAgWrapper,
      cellEditorParams: catalog !== StudyLinkCatalogEnum.Stan && { catalog },
      valueGetter: ({ data }) => getPartString(data, catalog),
      valueSetter: matchedPartValueSetter({
        ...props,
        gridApi: gridRef?.current?.api,
        gridRef,
      }),
      filter: 'agMultiColumnFilter',
      filterParams: {
        filters: [
          {
            filter: 'agSetColumnFilter',
            floatingFilter: true,
          },
          {
            filter: 'MovedFromAIFilter',
            filterParams: {
              catalog,
            },
          },
        ],
      },
      cellStyle: ({ node }) => {
        if (!node?.group && node.data && isSupplierState(gridRef.current?.step)) {
          const rowValue = node.data
          if (rowValue[`bamf${getCatalogPrefix(catalog)}Id`]) {
            return {
              backgroundColor: '#FABD2F',
              color: '#282828',
            }
          }
        }
        return {}
      },
    },
    {
      colId: 'matchDescription',
      headerName: 'Matched Description',

      valueGetter: ({ data, node }) => {
        if (node?.group) {
          return undefined
        }
        const studyLink: StudyLinkType = data
        if (studyLink) {
          if (catalog === StudyLinkCatalogEnum.Stan) {
            return studyLink.stanPartDescription
          } else if (catalog === StudyLinkCatalogEnum.Gudid) {
            return studyLink.gudidDescription
          } else if (catalog === StudyLinkCatalogEnum.Medline) {
            return studyLink.medDescription
          }
        }

        return ''
      },
    },
    {
      colId: 'matchCategory',
      headerName: 'Matched Category',

      valueGetter: ({ data, node }) => {
        if (node?.group) {
          return undefined
        }
        return data.matchCategory || ''
      },
    },
    {
      colId: 'vendor',
      headerName: 'IN Vendor',
      field: 'vendor',
      enableRowGroup: true,
      showRowGroup: false,
    },
    {
      colId: 'venitem',
      headerName: 'IN Vendor Item',
      field: 'venitem',
      editable: true,
      valueSetter: ({ data, node, newValue }) => {
        if (node?.group) {
          message.error('You can only edit venitem for one transaction at a time')
          return false
        }
        if (gridRef.current?.onRangeFilling) {
          return false
        }
        if (newValue !== undefined && newValue !== data.venitem) {
          Buttons.bulkUpdateStudyLinks(
            {
              studyId,
              tsIds: [data.tsId],
              update: newValue && {
                venitem: newValue.toString(),
              },
            },
            gridRef?.current?.api,
          )
            .then(() => message.success('updated'))
            .catch(e => message.error(e.message))
          return true
        }
        return false
      },
    },
    {
      colId: 'manufacturer',
      headerName: 'IN Manufacturer',
      field: 'manufacturer',
      enableRowGroup: true,
      showRowGroup: false,
    },
    {
      colId: 'mfgitem',
      headerName: 'IN Manufacturer Item',
      field: 'mfgitem',
      editable: true,
      valueSetter: ({ data, node, newValue }) => {
        if (node?.group) {
          message.error('You can only edit mfgitem for one transaction at a time')
          return false
        }
        if (gridRef.current?.onRangeFilling) {
          return false
        }
        if (newValue !== undefined && newValue !== data.mfgitem) {
          bulkUpdateStudyLinks(
            {
              studyId,
              tsIds: [data.tsId],
              update: newValue && {
                mfgitem: newValue.toString(),
              },
            },
            gridRef?.current?.api,
          )
            .then(() => message.success('updated'))
            .catch(e => message.error(e.message))
          return true
        }
        return false
      },
    },
    {
      colId: 'description',
      headerName: 'IN Description',
      field: 'description',
      editable: true,
      valueSetter: () => false,
    },
    {
      colId: 'qtypurchase',
      headerName: 'Quantity Purchase',
      field: 'qtypurchase',
      filter: 'agNumberColumnFilter',
      type: 'numericColumn',
    },
    {
      colId: 'asp',
      headerName: 'ASP',
      field: 'asp',
      valueFormatter: currencyFormatter,
      filter: 'agNumberColumnFilter',
      type: 'numericColumn',
    },
    {
      colId: 'totalSpend',
      headerName: 'Total Spend',
      field: 'totalSpend',
      valueFormatter: currencyFormatter,
      filter: 'agNumberColumnFilter',
      type: 'numericColumn',
    },
    {
      headerName: 'State',
      colId: 'state',
      valueGetter: ({ data, node }) => {
        if (node?.group) {
          return undefined
        }
        const studyLink = data as StudyLinkType
        if (studyLink) {
          if (catalog === StudyLinkCatalogEnum.Stan) {
            return studyLink.stanState
          } else if (catalog === StudyLinkCatalogEnum.Gudid) {
            return studyLink.gudidState
          } else if (catalog === StudyLinkCatalogEnum.Medline) {
            return studyLink.medState
          }
        }

        return undefined
      },
      hide: true,
      filter: StudyTransactionStateFilter,
    },
    ...(catalog === StudyLinkCatalogEnum.Stan ? stanColumns : []),
    {
      colId: 'approved',
      headerName: 'Approved',
      valueGetter: ({ data: rawData, node }) => {
        if (node?.group) {
          return ''
        }
        const data: StudyLinkType = rawData
        const approved: string[] = []
        if (data.stanState === StudyTransactionState.FinalReview) {
          approved.push('S')
        }
        if (data.gudidState === StudyTransactionState.FinalReview) {
          approved.push('U')
        }
        if (data.medState === StudyTransactionState.FinalReview) {
          approved.push('C')
        }
        return approved.join('|')
      },
    },
  ]
}

function matchStateFilterComparator(a: MatchState, b: MatchState) {
  return translateMatchToNumber(a) - translateMatchToNumber(b)
}

type MatchState = 'error' | 'success' | 'warning' | 'attention' | 'risky'

const translateMatchToNumber = (matchState: MatchState) => {
  switch (matchState) {
    case 'success':
      return 1
    case 'attention':
      return 2
    case 'warning':
      return 3
    case 'risky':
      return 4
    case 'error':
      return 5
  }
}

export const getManufacturerString = (data: StudyLinkType, catelog: StudyLinkCatalogEnum) => {
  switch (catelog) {
    case StudyLinkCatalogEnum.Stan: {
      if (data && data.stanManufacturerId) {
        return `${data.stanManufacturerId} | ${data.stanManufacturer || ''}`
      }
      return ''
    }
    case StudyLinkCatalogEnum.Medline: {
      return (data && data.medManufacturer) || ''
    }
    case StudyLinkCatalogEnum.Gudid:
    default: {
      return (data && (data.gudidManufacturer || data.gudidCompanyName)) || ''
    }
  }
}

export const getPartString = (data: StudyLinkType, catalog: StudyLinkCatalogEnum): string => {
  switch (catalog) {
    case StudyLinkCatalogEnum.Stan: {
      return (data && data.stanPartNumber) || ''
    }
    case StudyLinkCatalogEnum.Gudid: {
      return (data && data.gudidCatalogNumber) || ''
    }
    case StudyLinkCatalogEnum.Medline:
    default: {
      return (data && data.medSku) || ''
    }
  }
}

const getSupplierMatching = (
  props: Grooming2ContextProps & { isDataEnrichmentAdmin?: boolean },
  params: GetContextMenuItemsParams,
) => {
  const { studyId, gridRef } = props
  const { node, value, column } = params
  const ignoreRowMenuItem = !node?.group
    ? [
        {
          name: 'Ignore this item',
          action: () => {
            Buttons.handleIgnore(props)
          },
        },
      ]
    : []
  const input = getBlacklistMutationInput(params)
  if (input) {
    const field = node?.group ? node.field : column.getColDef().field
    const blacklistMenu = {
      name: `Blacklist this ${field}`,
      action: () => {
        bulkUpdateStudyLinks({ ...input, studyId }, gridRef?.current?.api)
      },
    }
    const ignoreSupplierMenu =
      ((field === 'vendor' || field === 'manufacturer') && [
        {
          name: `Ignore this ${field}`,
          action: () => {
            bulkUpdateStudyLinks(
              {
                studyId,
                [field]: value,
                update: {
                  [field === 'vendor' ? 'ignoreVendor' : 'ignoreManufacturer']: true,
                },
              },
              gridRef?.current?.api,
            )
          },
        },
      ]) ||
      []

    const ignoreItemMenu =
      ((field === 'venitem' || field === 'mfgitem') && [
        {
          name: `Ignore this ${field}`,
          action: () => {
            bulkUpdateStudyLinks(
              {
                studyId,
                [field]: value,
                update: {
                  [field === 'venitem' ? 'ignoreVenitem' : 'ignoreMfgitem']: true,
                },
              },
              gridRef?.current?.api,
            )
          },
        },
      ]) ||
      []

    return [
      ...(props.isDataEnrichmentAdmin ? [blacklistMenu] : []),
      ...ignoreItemMenu,
      ...ignoreSupplierMenu,
      ...ignoreRowMenuItem,
    ]
  }
  return [...ignoreRowMenuItem]
}

export const getCatalogPrefix = (catalog: StudyLinkCatalogEnum) => {
  switch (catalog) {
    case StudyLinkCatalogEnum.Gudid:
      return 'Gudid'
    case StudyLinkCatalogEnum.Medline:
      return 'Med'
    case StudyLinkCatalogEnum.Stan:
      return 'Stan'
  }
}

const getPartDetailMenuItems = (
  { column, node }: GetContextMenuItemsParams,
  { catalog, step }: Grooming2ContextProps,
): MenuItemDef[] => {
  const colId = column.getColId()
  if (node?.group) {
    return []
  }
  const prefix = getCatalogPrefix(catalog)

  if (colId === 'matchSupplier' || colId === 'matchedPart' || colId === 'versionModelNumber') {
    const rowValue: StudyLinkType = node.data
    const partId = isSupplierState(step)
      ? rowValue[`match${prefix}Id`]
      : rowValue.aiGudidOverwrite
      ? rowValue[`match${prefix}Id`]
      : rowValue[`bamf${prefix}Id`] || rowValue[`match${prefix}Id`]
    if (partId) {
      const posiblePartIdPrefix =
        catalog === StudyLinkCatalogEnum.Stan ? 'stn-' : catalog === StudyLinkCatalogEnum.Gudid ? 'udi-' : 'med-'
      const partDetailUrlPrefix =
        catalog === StudyLinkCatalogEnum.Gudid
          ? 'https://accessgudid.nlm.nih.gov/devices/'
          : 'https://medline.com/sku/item/MDP'
      const finalPartId = partId.startsWith(posiblePartIdPrefix) ? partId.substring(posiblePartIdPrefix.length) : partId
      return [
        {
          name: 'View Part Detail',
          action: () => {
            window.open(`${partDetailUrlPrefix}${finalPartId}`)
          },
        },
      ]
    }
  }
  return []
}

const getHIMatchContextMenuItems = (props: Grooming2ContextProps): MenuItemDef[] => {
  const { gridRef, lastActionStackHook, studyId, catalog } = props
  return [
    {
      name: 'Approve',
      action: () => {
        Buttons.handleApprove(props)
      },
    },
    {
      name: 'Move To Twin',
      action: () => {
        doBulkMoveToTwinMatch(
          { studyId, tsIds: getSelectedRows(gridRef).map(row => row.tsId), catalog },
          gridRef,
          lastActionStackHook,
          catalog,
        )
      },
    },
    {
      name: 'Retry',
      action: () => {
        doRematchStudyLinks(
          {
            tsIds: getSelectedRows(gridRef).map(row => row.tsId),
            catalog,
          },
          gridRef,
          studyId,
        )
      },
    },
    {
      name: 'Unmatch',
      action: () => {
        Buttons.handleUnmatchStudyLinks(props)
      },
    },
    {
      name: 'Unmatch And Stop Suggesting',
      action: () => {
        Buttons.handleUnmatchStudyLinks(props, true)
      },
    },
    {
      name: 'Restore',
      action: () => {
        Buttons.handleRestoreOriginalFields(props)
      },
    },
  ]
}

const getContextMenuItems =
  (props: Grooming2ContextProps & { isDataEnrichmentAdmin?: boolean }) =>
  (params: GetContextMenuItemsParams): MenuItemDef[] => {
    const { gridRef } = props
    const step = gridRef.current?.step
    return [
      ...(isSupplierState(step) && step !== StudyTransactionState.SupplierMatchingAndFinalReview
        ? getSupplierMatching(props, params)
        : []),
      ...(step ? getPartDetailMenuItems(params, props) : []),
      ...(isSupplierState(step) ? getHIMatchContextMenuItems(props) : []),
    ]
  }

const getBlacklistMutationInput = (
  params: GetContextMenuItemsParams,
): Pick<BulkUpdateStudyLinkInput, 'vendor' | 'manufacturer' | 'tsIds' | 'update'> | undefined => {
  if (params.node?.group) {
    switch (params.node.field) {
      case 'vendor': {
        return {
          vendor: Object.values<string>(params.node?.groupData)[0],
          update: {
            blacklistVendor: true,
          },
        }
      }
      case 'manufacturer': {
        return {
          manufacturer: Object.values<string>(params.node?.groupData)[0],
          update: {
            blacklistManufacturer: true,
          },
        }
      }
    }
  }
  switch (params.column.getColDef().field) {
    case 'vendor': {
      return {
        vendor: params.value,
        update: {
          blacklistVendor: true,
        },
      }
    }
    case 'manufacturer': {
      return {
        manufacturer: params.value,
        update: {
          blacklistManufacturer: true,
        },
      }
    }
    case 'venitem': {
      if (params.node.data) {
        return {
          tsIds: [params.node.data.tsId],
          update: {
            blacklistVenitem: true,
          },
        }
      }
      message.error('You can only blacklist venitem/mfgitem one at a time')
      return undefined
    }
    case 'mfgitem': {
      if (params.node.data) {
        return {
          tsIds: [params.node.data.tsId],
          update: {
            blacklistMfgitem: true,
          },
        }
      }
      message.error('You can only blacklist venitem/mfgitem one at a time')
      return undefined
    }
    default: {
      return undefined
    }
  }
}

const MatchingCheckAgRenderer = ({ value }) => {
  if (value === undefined) {
    return ''
  }
  if (value === 'success') {
    return <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
  }
  if (value === 'error') {
    return <Icon type="close-circle" twoToneColor="#eb2f96" theme="twoTone" />
  }
  if (value === 'attention') {
    return <Icon type="question-circle" twoToneColor="#1F7DFA" theme="twoTone" />
  }
  if (value === 'risky') {
    return <Icon type="warning" twoToneColor="#F48014" theme="twoTone" />
  }
  if (value === 'isReprocessed') {
    return (
      <>
        <Icon type="redo" />
        isReprocessed
      </>
    )
  }
  if (value === 'supplierProducesReprocessedItems') {
    return (
      <>
        <Icon type="redo" twoToneColor="#F48014" theme="twoTone" />
        Mfg reprocessed
      </>
    )
  }
  return <Icon type="info-circle" theme="twoTone" twoToneColor="#ffd75a" />
}

const handleOnFillEnd =
  (props: Grooming2ContextProps) =>
  async ({ api, finalRange, initialRange }: FillEndEvent) => {
    const { catalog, studyId, gridRef } = props
    if (finalRange.columns.length > 1) {
      return
    }
    const selectedColumn = finalRange.columns[0]
    const initTransaction =
      initialRange.endRow && (api.getDisplayedRowAtIndex(initialRange.endRow.rowIndex)?.data as StudyLinkType)
    if (!initTransaction) {
      return
    }
    const updatingParams = ((): Partial<BaseUpdateStudyLink> | undefined => {
      switch (selectedColumn.getColDef().colId) {
        case 'matchSupplier': {
          // stan match supplier
          if (catalog === StudyLinkCatalogEnum.Stan) {
            return { stanManufacturerId: initTransaction.stanManufacturerId }
          } else if (catalog === StudyLinkCatalogEnum.Medline) {
            return { medManufacturer: initTransaction.medManufacturer }
          } else {
            return { gudidManufacturer: initTransaction.gudidManufacturer }
          }
        }
        case 'matchedPart': {
          const updateProp: keyof StudyLinkType =
            catalog === StudyLinkCatalogEnum.Stan
              ? 'matchStanId'
              : catalog === StudyLinkCatalogEnum.Medline
              ? 'matchMedId'
              : 'matchGudidId'
          return initTransaction && { [updateProp]: initTransaction[updateProp] }
        }
        case 'gic':
        case 'typeOne':
        case 'typeTwo':
        case 'material':
        case 'segment':
          return {
            [`${selectedColumn.getColDef().colId}Id`]: initTransaction[`${selectedColumn.getColDef().colId}Id`],
          }
        default:
          return undefined
      }
    })()

    if (updatingParams === undefined) {
      return
    }

    const start = finalRange.startRow!.rowIndex
    const end = finalRange.endRow!.rowIndex
    const selectedIds = range(start, end + 1).map(
      index => (api.getDisplayedRowAtIndex(index)?.data as StudyLinkType).tsId,
    )

    return bulkUpdateStudyLinks(
      {
        studyId,
        catalog,
        tsIds: selectedIds,
        update: updatingParams,
      },
      gridRef?.current?.api,
    )
  }

const PageHeaderSubtitleWrapper = styled.div`
  display: flex;
`

function isFirstColumn({ columnApi, column }: IHeaderParams) {
  return columnApi.getAllDisplayedColumns()[0] === column
}

const isSupplierState = (state: StudyTransactionState | null | undefined) =>
  state === StudyTransactionState.SupplierMatching ||
  state === StudyTransactionState.Hidden ||
  state === StudyTransactionState.SupplierMatchingAndFinalReview
