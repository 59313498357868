import React, { useCallback, useState } from 'react'
import { Select, Icon } from 'antd'
import { debounce } from 'lodash'
import { SelectProps } from 'antd/lib/select'
import { useNormalizedCurvoSuggestionsQuery } from '@curvo/apollo'

const { Option } = Select

export const NormalizedCurvoSuggestionsSelect: React.FC<
  SelectProps<string> & {
    onCreateNormalizedCurvo: (norm: string) => void
  }
> = ({ onCreateNormalizedCurvo, ...props }) => {
  const [searchText, setSearchText] = useState('')
  const { loading, data } = useNormalizedCurvoSuggestionsQuery({ variables: { searchText } })
  const suggestions = (data && data.normalizedCurvoSuggestions) || []

  const dropdownRender = useCallback(
    menu =>
      suggestions.length > 0 ? (
        menu
      ) : (
        <div
          style={{ padding: '4px 8px', cursor: 'pointer' }}
          onMouseDown={e => e.preventDefault()}
          onClick={() => {
            onCreateNormalizedCurvo(searchText)
          }}>
          <Icon style={{ marginRight: '8px' }} type="plus" />
          {`Add Normalized Curvo: ${searchText}`}
        </div>
      ),
    [searchText, onCreateNormalizedCurvo, suggestions.length],
  )

  return (
    <Select
      onSearch={debounce(setSearchText, 800)}
      loading={loading}
      showSearch
      filterOption={false}
      defaultActiveFirstOption={false}
      style={{ width: '100%' }}
      dropdownRender={dropdownRender}
      placeholder="Select Normalized Curvo"
      {...props}>
      {suggestions.map(suggestion => (
        <Option key={suggestion}>{suggestion}</Option>
      ))}
    </Select>
  )
}
