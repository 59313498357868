import '@ag-grid-community/all-modules/dist/styles/ag-grid.css'
import '@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css'
import {
  ColDef,
  ColGroupDef,
  ColumnState,
  DragStoppedEvent,
  GridApi,
  GridReadyEvent,
  IServerSideDatasource,
  IServerSideGetRowsParams,
  ServerSideStoreType,
} from '@ag-grid-community/core'
import { AgGridReact } from '@ag-grid-community/react'
import { AllModules, ModuleRegistry } from '@ag-grid-enterprise/all-modules'
import { LazyQueryExecFunction } from '@apollo/client'
import {
  BlocklistMatchingsQueryArgs,
  Query,
  removeBlocklistMatchingsMutation,
  useLazyBlocklistMatchingsQuery,
} from '@curvo/apollo'
import { Button, Pagination, message } from 'antd'
import { pick } from 'lodash'
import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import { columns } from './BlocklistMatchingSuggestionColumns'

const BLOCKLIST_MATCHING_GRID = 'BLOCKLIST_MATCHING_GRID'

ModuleRegistry.registerModules(AllModules)
export const BlocklistMatchingSuggestion: React.FC = () => {
  const [getData, { loading }] = useLazyBlocklistMatchingsQuery({
    notifyOnNetworkStatusChange: true,
  })
  const [gridApi, setGridApi] = useState<GridApi | null>(null)
  const [pagination, setPagination] = useState<{ total: number; current: number; pageSize: number }>({
    total: 0,
    current: 1,
    pageSize: 100,
  })
  const [rowSelected, setRowSelected] = useState<boolean>(false)

  const serverSideDatasource = useMemo(() => {
    return new ServerSideDataSource(getData)
  }, [getData])

  const handleRemoveRows = useCallback(() => {
    const selectedRows = gridApi?.getSelectedRows()
    if (!selectedRows?.length) {
      return
    }
    gridApi?.showLoadingOverlay()
    return removeBlocklistMatchingsMutation({ ids: selectedRows.map(r => r.id) })
      .then(() => {
        message.success('Removed!')
        gridApi?.deselectAll()
        gridApi?.refreshServerSideStore({ purge: true })
      })
      .finally(() => gridApi?.hideOverlay())
  }, [gridApi])

  return (
    <Container>
      <ToolbarContainer>
        <Button disabled={!rowSelected} icon="delete" onClick={handleRemoveRows}>
          Unblock
        </Button>
      </ToolbarContainer>
      <div className="ag-theme-balham" style={{ height: '70vh', width: '100%' }}>
        <AgGridReact
          columnDefs={columns}
          rowModelType="serverSide"
          serverSideStoreType={ServerSideStoreType.Partial}
          cacheBlockSize={pagination.pageSize}
          rowBuffer={pagination.pageSize}
          maxBlocksInCache={10}
          blockLoadDebounceMillis={1000}
          defaultColDef={{ filter: true, sortable: true, resizable: true, autoHeight: true }}
          serverSideDatasource={serverSideDatasource}
          getRowNodeId={row => row.id}
          overlayNoRowsTemplate={loading ? 'Loading...' : 'No Rows To Show'}
          frameworkComponents={{ customCellLoading: CustomCellLoading }}
          loadingCellRenderer={'customCellLoading'}
          rowSelection={'multiple'}
          onSelectionChanged={e => {
            setRowSelected(!!e.api.getSelectedRows().length)
          }}
          onDragStopped={(e: DragStoppedEvent) => {
            const columnState = JSON.stringify(
              e.columnApi.getColumnState().map(colState => pick(colState, ['colId', 'width', 'pinned', 'hide'])),
            )
            localStorage.setItem(BLOCKLIST_MATCHING_GRID, columnState)
          }}
          onPaginationChanged={event => {
            setPagination({
              current: event.api.paginationGetCurrentPage(),
              total: event.api.paginationGetRowCount(),
              pageSize: event.api.paginationGetPageSize(),
            })
          }}
          onGridReady={({ api, columnApi }: GridReadyEvent) => {
            setGridApi(api)
            const columnStateStr = localStorage.getItem(BLOCKLIST_MATCHING_GRID)
            if (columnStateStr) {
              const savedColumnStates: ColumnState[] = JSON.parse(columnStateStr)
              const currentColumnStates = columnApi.getColumnState()
              const unsavedColumns = currentColumnStates.filter(
                col => !savedColumnStates.find(savedCol => savedCol.colId === col.colId),
              )

              columnApi.setColumnState([...savedColumnStates, ...unsavedColumns])
              columnApi.setColumnVisible('state', false)

              columnApi.moveColumn('index', 0)
            }

            columnApi.getColumn('id')?.setSort('desc')
            api.onFilterChanged()
          }}
          columnTypes={{
            number: {
              filter: 'agNumberColumnFilter',
              filterParams: { suppressAndOrCondition: true, buttons: ['clear'] },
            },
            text: { filter: 'agTextColumnFilter', filterParams: { suppressAndOrCondition: true, buttons: ['clear'] } },
          }}
          pagination
          suppressPaginationPanel
        />
        <PaginationWrapper>
          <Pagination
            disabled={loading}
            current={pagination.current + 1}
            total={pagination.total}
            pageSize={pagination.pageSize}
            pageSizeOptions={['100', '200', '500', '1000']}
            showSizeChanger
            showQuickJumper
            onChange={page => {
              gridApi?.paginationGoToPage(page - 1)
            }}
            onShowSizeChange={(_current, size) => {
              // gridApi?.paginationGoToPage(1)
              gridApi?.paginationSetPageSize(size)
            }}
          />
        </PaginationWrapper>
      </div>
    </Container>
  )
}

const Container = styled.div`
  margin: 24px;
`

const PaginationWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: end;
`

const ToolbarContainer = styled.div`
  margin: 16px 0;
  display: flex;
  gap: 8px;
`

class ServerSideDataSource implements IServerSideDatasource {
  private getData: LazyQueryExecFunction<Pick<Query, 'blocklistMatchings'>, BlocklistMatchingsQueryArgs>

  constructor(getData: LazyQueryExecFunction<Pick<Query, 'blocklistMatchings'>, BlocklistMatchingsQueryArgs>) {
    this.getData = getData
  }

  getRows(params: IServerSideGetRowsParams): void {
    const request = params.request
    params.api.showLoadingOverlay()
    this.getData({
      variables: {
        selectedFields: columns
          .flatMap(c => (c as ColGroupDef).children || c)
          .filter(c => !!(c as ColDef).field)
          .map(c => (c as ColDef).field!),
        startRow: request.startRow || 0,
        endRow: request.endRow || 100,
        sorting: request.sortModel || [],
        filter: Object.keys(request.filterModel || {}).map(k => ({
          field: k,
          ...request.filterModel[k],
          filter: request.filterModel[k].filter?.toString(),
          filterTo: request.filterModel[k].filterTo?.toString(),
        })),
      },
      fetchPolicy: 'network-only',
    })
      .then(resp => {
        const rowData = resp.data?.blocklistMatchings.edges.map(e => e.node) || []
        params.success({
          rowData,
          rowCount: resp.data?.blocklistMatchings.metadata.total || rowData.length,
        })
      })
      .catch(() => {
        params.fail()
      })
      .finally(() => {
        params.api.hideOverlay()
      })
  }
}

const CustomCellLoading = () => {
  return <></>
}
