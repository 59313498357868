import {
  ColumnState,
  DragStoppedEvent,
  GetContextMenuItemsParams,
  GridApi,
  GridReadyEvent,
} from '@ag-grid-community/core'
import { AgGridColumnProps } from '@ag-grid-community/react'
import { AgGridReact } from '@ag-grid-community/react/lib/agGridReact'
import { AllModules } from '@ag-grid-enterprise/all-modules'
import {
  Manufacturer,
  ManufacturerType,
  NormalizedSupplierExt,
  bulkUpsertNormalizedSuppliersMutation,
  useStudyNormalizedSuppliersMatchQuery,
} from '@curvo/apollo'
import { Button, PageHeader, Tag, Tooltip, message } from 'antd'
import Select, { LabeledValue } from 'antd/lib/select'
import { pick } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { ControlsWrapper } from './TransactionGroom'
import { currencyFormatter } from './common'
import { ManufacturerSelectAgWrapperNormSuppliers } from './components/ManufacturerSelectAgWrapper'
import { ManufacturerTypeSelectAgWrapper } from './components/ManufacturerTypeSelect'
import { MultipleManufacturerSelectAgWrapperNormalizedSupplier } from './components/MultipleManufacturerSelectAgWrapper'
import { NormalizedCurvoSuggestionsAgSelect } from './components/NormalizedCurvoSuggestionsAgSelect'

type AgGridRefProps = AgGridReact & {
  api: GridApi
}

type NormalizedType = 'normalized' | 'unNormalized' | 'partial'

const SUPPLIER_GROOM = 'SUPPLIER_GROOM '

type NormalizedSupplierChanges = {
  [inputSupplier: string]: NormalizedSupplierExt
}

export const SuppliersGroom: React.FC = () => {
  const [updating, setUpdating] = useState(false)
  const { studyId } = useParams<{ studyId: string }>()
  const navigate = useNavigate()

  const gridRef = useRef<AgGridRefProps>(null)
  const { data } = useStudyNormalizedSuppliersMatchQuery({
    variables: { id: parseInt(studyId!, 10) },
    fetchPolicy: 'network-only',
  })

  const [normsGroup, setNormsGroup] = useState<{
    unNormalized?: NormalizedSupplierExt[]
    normalized?: NormalizedSupplierExt[]
    partial?: NormalizedSupplierExt[]
  }>({})

  const norms = data && data.studySuppliersMatch

  useEffect(() => {
    const unNormalized = norms && norms.filter(norm => !norm.manufacturer && !norm.normalizedCurvo)
    const normalized = norms && norms.filter(norm => norm.manufacturer)
    const partial = norms && norms.filter(norm => norm.normalizedCurvo && !norm.manufacturer)
    setNormsGroup({
      unNormalized,
      normalized,
      partial,
    })
  }, [norms])

  const [changes, setChanges] = useState<NormalizedSupplierChanges>({})
  const [stateFilter, setStateFilter] = useState<NormalizedType>('unNormalized')

  const onChange = (newData: NormalizedSupplierExt) => {
    setChanges(oldChanges => ({
      ...oldChanges,
      [newData.inputSupplier]: newData,
    }))
  }

  const colDefs: AgGridColumnProps[] = [
    {
      headerName: 'Input Supplier',
      field: 'inputSupplier',
      colId: 'inputSupplier',
      onCellValueChanged: () => {},
    },
    {
      headerName: 'Normalized Curvo',
      field: 'normalizedCurvo',
      editable: true,
      colId: 'normalizedCurvo',
      cellEditorFramework: NormalizedCurvoSuggestionsAgSelect,
      valueSetter: ({ newValue, data: newData }) => {
        if (newData.normalizedCurvo !== newValue) {
          newData.normalizedCurvo = newValue
          return true
        }
        return false
      },
      cellRendererParams: { changes },
    },
    {
      headerName: 'Type',
      field: 'type',
      colId: 'type',
      editable: true,
      cellEditorFramework: ManufacturerTypeSelectAgWrapper,
      filter: 'agSetColumnFilter',
      valueSetter: ({ newValue, data: newData }) => {
        if (newValue !== undefined && newValue !== newData.type) {
          newData.type = newValue
          return true
        }
        return false
      },
      valueFormatter: ({ value }) => {
        switch (value) {
          case ManufacturerType.I:
            return 'Instruments'
          case ManufacturerType.O:
            return 'Ortho'
          case ManufacturerType.P:
            return 'PPI'
          case ManufacturerType.L:
            return 'Laboratory'
          case ManufacturerType.Other:
            return 'Other'
          case ManufacturerType.Unknown:
            return 'Unknown'
          case 'B':
            return 'Blacklist'
          default: {
            return value
          }
        }
      },
    },
    {
      headerName: 'Retry',
      field: 'retry',
      editable: true,
      cellEditorFramework: MultipleManufacturerSelectAgWrapperNormalizedSupplier,
      cellRendererFramework: ({ value }) => {
        if (!value) {
          return ''
        }
        return (value as Pick<Manufacturer, 'id' | 'name'>[]).map(m => (
          <Tooltip title={m.name} key={m.id}>
            <Tag>{m.id}</Tag>
          </Tooltip>
        ))
      },
      valueSetter: ({ newValue, data: newData }) => {
        newData.retry =
          newValue &&
          newValue.map((retry: LabeledValue) => ({
            id: retry.key,
            name: retry.label,
          }))
        return true
      },
      colId: 'retry',
    },
    {
      headerName: 'Manufacturer',
      field: 'manufacturer.id',
      colId: 'manufacturerId',
      editable: true,
      cellEditorFramework: ManufacturerSelectAgWrapperNormSuppliers,
      valueGetter: ({ data: newData }) => {
        if (newData && newData.manufacturer) {
          return `${newData.manufacturer.id} | ${newData.manufacturer.name || ''}`
        }
        return ''
      },
      valueSetter: ({ newValue, data: nData, node }) => {
        if (newValue !== undefined) {
          const newData = {
            ...nData,
            manufacturer: newValue,
            type: newValue && newValue.type,
            retry: newValue && newValue.retry,
          }
          node?.setData(newData)
        }

        return true
      },
    },
    {
      headerName: 'Total Spend',
      field: 'totalSpend',
      colId: 'totalSpend',
      type: 'numericColumn',
      valueFormatter: currencyFormatter,
    },
  ]

  const FilterExtra = (
    <Select value={stateFilter} onChange={(v: NormalizedType) => setStateFilter(v)} style={{ width: 200 }}>
      <Select.Option value="normalized">Normalized</Select.Option>
      <Select.Option value="unNormalized">Un-Normalized</Select.Option>
      <Select.Option value="partial">Partial</Select.Option>
    </Select>
  )

  const saveButtonHandle = () => {
    const input = Object.values(changes).map(v => ({
      ...v,
      __typename: undefined,
      manufacturer: undefined,
      totalSpend: undefined,
      manufacturerId: v.manufacturer && v.manufacturer.id,
      retry: v.retry && v.retry.map(retry => pick(retry, ['id', 'name'])),
    }))
    setUpdating(true)
    bulkUpsertNormalizedSuppliersMutation({ input })
      .then(() => {
        message.success('updated')
        setChanges({})
      })
      .catch(e => message.error(e.message))
      .finally(() => setUpdating(false))
  }

  return (
    <div style={{ height: '100%', flexDirection: 'column', display: 'flex' }}>
      <PageHeader
        title={'Normalized Supplier Grooming'}
        onBack={() => navigate('/data-cleaning/')}
        extra={FilterExtra}
      />
      <div
        style={{
          marginLeft: '24px',
          marginRight: '24px',
          display: 'flex',
          flexDirection: 'column',
          flex: '1 1 auto',
        }}>
        <div className="ag-theme-balham" style={{ flex: 1, minHeight: '200px', width: 'fill-parent' }}>
          <AgGridReact
            ref={gridRef}
            onCellValueChanged={({ data: newData, column }) => {
              if (column.colId !== 'inputSupplier') {
                onChange(newData)
              }
            }}
            getContextMenuItems={({ column, node }: GetContextMenuItemsParams) => {
              if (column.getId() === 'normalizedCurvo' && !node.group) {
                return [
                  {
                    name: 'Set NULL',
                    action: () => {
                      node.setData({
                        ...node.data,
                        normalizedCurvo: null,
                      })
                    },
                  },
                  'copy',
                  'paste',
                ]
              }
              return ['copy', 'paste']
            }}
            onDragStopped={(e: DragStoppedEvent) => {
              const columnState = JSON.stringify(
                e.columnApi.getColumnState().map(colState => pick(colState, ['colId', 'width', 'pinned', 'hide'])),
              )
              localStorage.setItem(SUPPLIER_GROOM, columnState)
            }}
            onGridReady={({ api, columnApi }: GridReadyEvent) => {
              const columnStateStr = localStorage.getItem(SUPPLIER_GROOM)
              if (columnStateStr) {
                const savedColumnStates: ColumnState[] = JSON.parse(columnStateStr)
                const currentColumnStates = columnApi.getColumnState()
                const unsavedColumns = currentColumnStates.filter(
                  col => !savedColumnStates.find(savedCol => savedCol.colId === col.colId),
                )

                columnApi.setColumnState([...savedColumnStates, ...unsavedColumns])
                columnApi.setColumnVisible('state', false)

                columnApi.moveColumn('index', 0)
              }
              api.onFilterChanged()
            }}
            immutableData
            defaultColDef={{
              sortable: true,
              filter: true,
              resizable: true,
              headerCheckboxSelectionFilteredOnly: true,
            }}
            getRowNodeId={(row: NormalizedSupplierExt) => row.inputSupplier}
            enableFillHandle
            enableRangeSelection
            columnDefs={colDefs}
            modules={AllModules}
            rowData={normsGroup[stateFilter]}
          />
        </div>

        <ControlsWrapper>
          <Button loading={updating} onClick={() => saveButtonHandle()}>
            Save
          </Button>
        </ControlsWrapper>
      </div>
    </div>
  )
}
