import {
  approveQueuedUpdatePartsMutation,
  bulkUpdateQueuedPartMutation,
  Client,
  COUNT_QUEUED_PARTS,
  insertPartsFromQueueMutation,
  NewPart,
  QueuedUpdatePartsData,
  QueuedUpdatePartsQueryArgs,
  UpdatePartDataType,
} from '@curvo/apollo'
import { Button, message, Pagination, Popconfirm, Row, Table, Tag } from 'antd'
import { ColumnProps } from 'antd/es/table'
import { LabeledValue } from 'antd/lib/select'
import Axios from 'axios'
import { omit } from 'lodash'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import xlsx from 'xlsx'
import { underscoreArray } from '../../../../components/common'
import { useSelectableTable } from '../../../../components/SelectableTable'
import Cognito from '../../../../config/Cognito'
import { DropdownFilter } from '../../Update/components/DropdownFilter'
import { BrandSelect } from '../../Update/components/Select/BrandSelect'
import { GICSelect } from '../../Update/components/Select/GICSelect'
import { ManufacturerSelect } from '../../Update/components/Select/ManufacturerSelect'
import { ProductLineSelect } from '../../Update/components/Select/ProductLineSelect'
import { NewPartsStepContentProps, StyledPaginationWrapper } from '../common'
import { BulkUpdateQueuedPartPanel, NewPartsBulkUpdateInputValues } from './Panel/BulkUpdatePart'

const rowKeyGen = (record: NewPart) => `${record.partNumber}.${record.partName}`

export const NewPartsDataStep: React.FC<NewPartsStepContentProps> = ({ currentStep, batchName }) => {
  const [currentPage, setCurrentPage] = useState({
    [UpdatePartDataType.Approving]: 1,
    [UpdatePartDataType.Discarded]: 1,
    [UpdatePartDataType.Inserting]: 1,
  })
  const [limitPerPage, setLimitPerPage] = useState({
    [UpdatePartDataType.Approving]: 10,
    [UpdatePartDataType.Discarded]: 10,
    [UpdatePartDataType.Inserting]: 10,
  })

  const [queryArgs, setQueryArgs] = useState<QueuedUpdatePartsQueryArgs>({
    type: UpdatePartDataType.Approving,
    first: limitPerPage[UpdatePartDataType.Approving],
    skip: 0,
    batchName,
  })

  useEffect(() => {
    setQueryArgs(old => ({
      ...old,
      batchName,
    }))
  }, [batchName])

  const [loadingState, setLoadingState] = useState({
    approve: false,
    discard: false,
    insert: false,
    update: false,
  })
  const [isOpenPanel, setIsOpenPanel] = useState(false)

  const { columns, setMultipleSelection, rowSelection, selected, isSelectedAll, cleanSelected } = useSelectableTable(
    newPartColumns,
    [],
    rowKeyGen,
    true,
  )
  useEffect(() => setMultipleSelection(true), [setMultipleSelection])
  useEffect(() => {
    if (currentStep === 1) {
      setQueryArgs(q => ({
        ...q,
        type: UpdatePartDataType.Discarded,
      }))
    } else if (currentStep === 2) {
      setQueryArgs(q => ({
        ...q,
        type: UpdatePartDataType.Approving,
      }))
    } else {
      setQueryArgs(q => ({
        ...q,
        type: UpdatePartDataType.Inserting,
      }))
    }
  }, [currentStep])

  return (
    <QueuedUpdatePartsData variables={queryArgs} fetchPolicy="network-only">
      {({ data, error, loading, refetch }) => {
        if (error) {
          message.error(error.message)
        }
        const queuedUpdateParts =
          data && data.queuedUpdateParts ? data.queuedUpdateParts.edges.map(edge => edge.node) : []
        const totalPages = data && data.queuedUpdateParts ? data.queuedUpdateParts.metadata.total : 0
        const handleApprove = () => {
          message.info('Approving...')
          setLoadingState({
            ...loadingState,
            approve: true,
          })
          approveQueuedUpdatePartsMutation(
            {
              input: {
                ids: selected.map(np => np.id),
                isInvertedSelection: isSelectedAll,
              },
            },
            {
              refetchQueries: [{ query: COUNT_QUEUED_PARTS }],
            },
          )
            .then(() => {
              refetch(queryArgs)
              message.success('Successfully approved')
            })
            .finally(() => {
              cleanSelected()
              setLoadingState({
                ...loadingState,
                approve: false,
              })
            })
        }
        const handleInsert = () => {
          message.info('Inserting...')
          setLoadingState({
            ...loadingState,
            insert: true,
          })
          insertPartsFromQueueMutation(
            { batchName },
            {
              refetchQueries: [{ query: COUNT_QUEUED_PARTS }],
            },
          )
            .then(() => {
              refetch(queryArgs)
              message.success('Successfully inserted')
            })
            .finally(() => {
              cleanSelected()
              setLoadingState({
                ...loadingState,
                insert: false,
              })
            })
        }

        const handleBulkEdit = (values: NewPartsBulkUpdateInputValues) => {
          const ids = selected.map(queuedPart => queuedPart.id)
          setLoadingState({
            ...loadingState,
            update: true,
          })
          bulkUpdateQueuedPartMutation(
            {
              input: { ids, isInvertedSelection: isSelectedAll, ...values },
            },
            {
              refetchQueries: [{ query: COUNT_QUEUED_PARTS }],
            },
            true,
            e => {
              message.error(e.message)
            },
          )
            .then(() => refetch(queryArgs))
            .finally(() => {
              cleanSelected()
              setLoadingState({
                ...loadingState,
                update: false,
              })
            })
          setIsOpenPanel(false)
        }

        const handleExport = async () => {
          if (!isSelectedAll && selected.length > 0) {
            const selectedWithErrors = selected.map(s => {
              const errors = getPartError(s)
              return {
                ...s,
                errors: errors.length > 0 ? 'Missing: ' + errors.join(', ') : '',
              }
            })
            const exportData = underscoreArray(
              selectedWithErrors.map(p =>
                omit(p, [
                  'id',
                  'partId',
                  'manufacturer',
                  'brandId',
                  'productLineId',
                  'gic',
                  'typeOneId',
                  'typeTwoId',
                  'materialId',
                  'segmentation',
                  'isReady',
                  '__typename',
                ]),
              ),
            ) as any[]
            const wb = xlsx.utils.book_new()
            const ws = xlsx.utils.json_to_sheet(exportData)
            xlsx.utils.book_append_sheet(wb, ws)
            xlsx.writeFile(wb, `${batchName}-errors.xlsx`)
            return
          }
          if (isSelectedAll) {
            const session = await Cognito.getSession()
            const token = session.getAccessToken().getJwtToken()
            const baseUrl = Client.getUrl()
            Axios.post(
              `${window.location.protocol}//${baseUrl}/download-queued-parts`,
              {},
              {
                responseType: 'blob',
                params: {
                  batchName: queryArgs.batchName,
                  type: 'Discarded',
                },
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              },
            ).then(response => {
              const url = window.URL.createObjectURL(new Blob([response.data]))
              const link = document.createElement('a')
              link.href = url
              link.setAttribute('download', `QueuedParts-${queryArgs.batchName}-${queryArgs.type}.xlsx`)
              document.body.appendChild(link)
              link.click()
            })
          }
        }

        return (
          <React.Fragment>
            <Row style={{ display: 'flex', justifyContent: 'end', marginBottom: '8px' }}>
              <Button disabled={!isSelectedAll && selected.length === 0} onClick={() => handleExport()}>
                {`Export ${isSelectedAll ? 'All' : `(${selected.length})`}`}
              </Button>
            </Row>
            <Table
              columns={
                queryArgs.type === UpdatePartDataType.Discarded ? columns : columns.slice(0, columns.length - 1) // no errors col
              }
              dataSource={queuedUpdateParts}
              loading={loading}
              pagination={false}
              rowKey={rowKeyGen}
              rowSelection={queryArgs.type === UpdatePartDataType.Inserting ? undefined : rowSelection}
            />
            <StyledPaginationWrapper>
              {queryArgs.type === UpdatePartDataType.Approving && (
                <Popconfirm title="Do you want to approve selected parts?" onConfirm={handleApprove}>
                  <StyledApproveButton
                    type="primary"
                    icon="check"
                    disabled={(!isSelectedAll && !selected.length) || loadingState.approve}>
                    Approve
                  </StyledApproveButton>
                </Popconfirm>
              )}
              {queryArgs.type === UpdatePartDataType.Inserting && (
                <Popconfirm title="Do you want to insert all approved parts?" onConfirm={handleInsert}>
                  <StyledApproveButton type="primary" icon="check" disabled={loadingState.insert}>
                    Insert
                  </StyledApproveButton>
                </Popconfirm>
              )}
              {(isSelectedAll || selected.length > 0) && (
                <StyledApproveButton
                  type="default"
                  icon="edit"
                  onClick={() => setIsOpenPanel(true)}
                  disabled={loadingState.update}>
                  Edit
                </StyledApproveButton>
              )}
            </StyledPaginationWrapper>
            <StyledPaginationWrapper>
              <Pagination
                total={totalPages}
                current={currentPage[queryArgs.type]}
                onChange={page => {
                  setQueryArgs({
                    ...queryArgs,
                    skip: (page - 1) * limitPerPage[queryArgs.type],
                  })
                  setCurrentPage({
                    ...currentPage,
                    [queryArgs.type]: page,
                  })
                }}
                onShowSizeChange={limit => {
                  setQueryArgs({
                    ...queryArgs,
                    skip: 0,
                    first: limit,
                  })
                  setLimitPerPage({
                    ...limitPerPage,
                    [queryArgs.type]: limit,
                  })
                }}
              />
            </StyledPaginationWrapper>
            <BulkUpdateQueuedPartPanel
              onCancel={() => setIsOpenPanel(false)}
              onSubmit={handleBulkEdit}
              visible={isOpenPanel}
              title="Edit multiple new Parts"
              width={800}
              newParts={isSelectedAll ? [] : selected}
            />
          </React.Fragment>
        )
      }}
    </QueuedUpdatePartsData>
  )
}

export const StyledNavigationButtonWrapper = styled.div`
  margin-top: 8px;
  margin-bottom: 16px;
  display: flex;
  width: 100%;
  flex-direction: row;
`

const StyledApproveButton = styled(Button)`
  margin-top: 16px;
  margin-left: 8px;
`

const newPartColumns: ColumnProps<NewPart>[] = [
  {
    title: 'Manufacturer',
    dataIndex: 'manufacturerId',
    width: 100,
    key: 'manufacturerId',
    render: (text: string, _record: NewPart, _index: number) => <BlueTextSpan>{text}</BlueTextSpan>,
    filterDropdown: props => (
      <DropdownFilter {...props}>
        {({ selected, setSelected }) => (
          <ManufacturerSelect value={selected} onChange={v => setSelected(v as LabeledValue)} />
        )}
      </DropdownFilter>
    ),
  },
  {
    title: 'Part Number',
    width: 100,
    dataIndex: 'partNumber',
    render: (text, record, _index) => (
      <span>
        {text} {!record.partId && <Tag color="red">New</Tag>}
      </span>
    ),
    key: 'number',
  },
  {
    title: 'Description',
    width: 150,
    dataIndex: 'partName',
    key: 'name',
  },
  {
    title: 'Brand',
    dataIndex: 'brand',
    width: 100,
    key: 'brand',
    render: (text: string, _record: NewPart, _index: number) => <BlueTextSpan>{text}</BlueTextSpan>,
    filterDropdown: props => (
      <DropdownFilter {...props}>
        {({ selected, setSelected }) => <BrandSelect value={selected} onChange={v => setSelected(v as LabeledValue)} />}
      </DropdownFilter>
    ),
  },
  {
    title: 'GIC',
    dataIndex: 'gicId',
    width: 100,
    key: 'gicId',
    render: (text: string, _record: NewPart, _index: number) => <BlueTextSpan>{text}</BlueTextSpan>,
    filterDropdown: props => (
      <DropdownFilter {...props}>
        {({ selected, setSelected }) => <GICSelect value={selected} onChange={setSelected} />}
      </DropdownFilter>
    ),
  },
  {
    title: 'Product Line',
    dataIndex: 'productLine',
    width: 120,
    key: 'productLine',
    render: (text: string, _record: NewPart, _index: number) => <BlueTextSpan>{text}</BlueTextSpan>,
    filterDropdown: props => (
      <DropdownFilter {...props}>
        {({ selected, setSelected }) => <ProductLineSelect value={selected} onChange={setSelected} />}
      </DropdownFilter>
    ),
  },
  {
    title: 'Errors',
    width: 100,
    key: 'errors',
    render: (_: string, record: NewPart, _index: number) => {
      const errors = getPartError(record)
      return errors.map(error => (
        <Tag color={errorColorMap[error]} key={error}>
          {error}
        </Tag>
      ))
    },
  },
]

const errorColorMap = {
  brand: 'magenta',
  gic: 'red',
  material: 'volcano',
  typeOne: 'green',
  typeTwo: 'cyan',
  manufacturer: 'blue',
  'product line': 'geekblue',
  segmentation: 'purple',
}

const BlueTextSpan = styled.span`
  color: #0288d1;
`

const getPartError = (part: NewPart): string[] => {
  const errors: string[] = []
  if (!part.brandId) {
    errors.push('brand')
  }
  if (!part.gicId) {
    errors.push('gic')
  }
  if (!part.materialId) {
    errors.push('material')
  }
  if (!part.typeOneId) {
    errors.push('typeOne')
  }
  if (!part.typeTwoId) {
    errors.push('typeTwo')
  }
  if (!part.manufacturerId) {
    errors.push('manufacturer')
  }
  if (!part.productLineId) {
    errors.push('product line')
  }
  if (!part.segmentationId) {
    errors.push('segmentation')
  }

  return errors
}
