import { useState, useEffect } from 'react'
import {
  NormalizedSupplier,
  NormalizedSuppliersData,
  NormalizedSuppliersQueryArgs,
  deleteNormalizedSupplierMutation,
  NORMALIZED_SUPPLIER,
  NormalizedSupplierUpdateInput,
  updateNormalizedSupplierMutation,
  createNormalizedSupplierMutation,
  NormalizedSupplierCreateInput,
} from '@curvo/apollo'
import Table, { ColumnProps } from 'antd/lib/table'
import { message, Button, Pagination, Popconfirm } from 'antd'
import { ResizableHeader } from '../../../components/ResizableColumnHeader'
import {
  PageWrapper,
  SearchWrapper,
  StyledSearchInput,
  PaginationContainer,
  ColumnGenFunctionType,
  RedA,
} from './common'
import { NormalizedSupplierDrawer } from './components/EditPanel/CreateEditNormalizedSupplier'
import { EditMode } from './components/EditPanel/EditManufacturer'

export const NormalizedSuppliers = () => {
  const [currentPage, setCurrentPage] = useState(1)

  const [limitPerPage, setLimitPerPage] = useState(10)
  const [queryArgs, setQueryArgs] = useState<NormalizedSuppliersQueryArgs>({
    first: limitPerPage,
    skip: 0,
  })

  const [editMode, setEditMode] = useState<EditMode>(EditMode.edit)
  const [selectedNormalized, setSelectedNormalized] = useState<NormalizedSupplier>()
  const [showPanel, setShowPanel] = useState(false)
  const [columns, setColumns] = useState<ColumnProps<NormalizedSupplier>[]>([])

  useEffect(() => {
    setColumns(
      columnsGen(
        normalized => {
          setSelectedNormalized(normalized)
          setShowPanel(true)
          setEditMode(EditMode.edit)
        },
        normalized => {
          handleDeleteNormalized(queryArgs, normalized)
        },
      ),
    )
  }, [queryArgs])
  const handleUpdateColumnSize = (index: number) => (size: number) => {
    const nextCols = [...columns]
    nextCols[index].width = size
    setColumns(nextCols)
  }

  return (
    <PageWrapper>
      <NormalizedSuppliersData variables={queryArgs} fetchPolicy="network-only" selfHandleError>
        {({ data, error, loading }) => {
          if (error) {
            message.error(error.message)
          }
          const normalizedSuppliers =
            data && data.normalizedSuppliers ? data.normalizedSuppliers.edges.map(edge => edge.node) : []
          const totalPages = data && data.normalizedSuppliers ? data.normalizedSuppliers.metadata.total : 0

          return (
            <div>
              <SearchWrapper>
                <StyledSearchInput
                  placeholder="Search normalized by name"
                  enterButton
                  onSearch={searchText => {
                    setQueryArgs(query => ({
                      ...query,
                      searchText,
                    }))
                  }}
                />
                <Button
                  type="primary"
                  icon="plus"
                  onClick={() => {
                    setEditMode(EditMode.create)
                    setSelectedNormalized(undefined)
                    setShowPanel(true)
                  }}>
                  New Normalized Supplier
                </Button>
              </SearchWrapper>
              <Table
                loading={loading}
                components={{ header: { cell: ResizableHeader } }}
                columns={columns.map((col, i) => ({
                  ...col,
                  onHeaderCell: () => ({ width: col.width, onResize: handleUpdateColumnSize(i) }),
                }))}
                dataSource={normalizedSuppliers}
                rowKey={norm => `${norm.inputSupplier}.${norm.normalizedCurvo}`}
                pagination={false}
                bordered
              />
              <PaginationContainer>
                <Pagination
                  total={totalPages}
                  defaultCurrent={currentPage}
                  onChange={pageNumber => {
                    setCurrentPage(pageNumber)
                    setQueryArgs(query => ({
                      ...query,
                      first: limitPerPage,
                      skip: (pageNumber - 1) * limitPerPage,
                    }))
                  }}
                  showSizeChanger
                  onShowSizeChange={(_, size) => setLimitPerPage(size)}
                />
              </PaginationContainer>
              <NormalizedSupplierDrawer
                width={300}
                title={editMode === EditMode.create ? 'New Normalized Supplier' : 'Edit Normalized Supplier'}
                editMode={editMode}
                onSubmit={input => {
                  if (editMode === EditMode.edit) {
                    handleUpdateNormalized(input, queryArgs)
                      .then(() => message.success('Updated normalized!'))
                      .catch(e => message.error(e.message))
                  } else {
                    handleCreateNormalized(input, queryArgs)
                      .then(() => message.success('Created new normalized!'))
                      .catch(e => message.error(e.message))
                  }
                  setShowPanel(false)

                  setSelectedNormalized(undefined)
                }}
                normalized={selectedNormalized}
                onCancel={() => setShowPanel(false)}
                visible={showPanel}
              />
            </div>
          )
        }}
      </NormalizedSuppliersData>
    </PageWrapper>
  )
}

const columnsGen: ColumnGenFunctionType<NormalizedSupplier> = (onEdit, onDelete) => [
  {
    title: 'Input Supplier',
    key: 'inputSupplier',
    width: 200,
    dataIndex: 'inputSupplier',
    sorter: true,
  },
  {
    title: 'Normalized Curvo',
    key: 'normalizedCurvo',
    width: 200,
    dataIndex: 'normalizedCurvo',
  },
  {
    title: 'Manufacturer',
    key: 'manufacturer.name',
    width: 150,
    dataIndex: 'manufacturer.name',
    render: (_text: any, record: NormalizedSupplier) =>
      record.manufacturer && `${record.manufacturer.id} | ${record.manufacturer.name}`,
  },
  {
    title: 'Type',
    key: 'type',
    width: 150,
    dataIndex: 'type',
  },
  {
    title: 'Action',
    key: 'action',
    width: 150,
    render: (_text: any, record: NormalizedSupplier) => (
      <span>
        <Button type="link" onClick={() => onEdit(record)}>
          Edit
        </Button>
        <span> | </span>
        <Popconfirm
          title={'Are you sure you want to delete this normalized?'}
          okText="Yes"
          cancelText="No"
          onConfirm={() => onDelete && onDelete(record)}>
          <RedA>Delete</RedA>
        </Popconfirm>
      </span>
    ),
  },
]

const handleDeleteNormalized = (currentQueryArgs: NormalizedSuppliersQueryArgs, normalized: NormalizedSupplier) =>
  deleteNormalizedSupplierMutation(
    {
      inputSupplier: normalized.inputSupplier,
      normalizedCurvo: normalized.normalizedCurvo,
    },
    {
      refetchQueries: [{ query: NORMALIZED_SUPPLIER, variables: currentQueryArgs }],
    },
  )

const handleUpdateNormalized = (
  updatingNormalized: NormalizedSupplierUpdateInput,
  currentQueryArgs: NormalizedSuppliersQueryArgs,
) =>
  updateNormalizedSupplierMutation(
    { input: updatingNormalized },
    { refetchQueries: [{ query: NORMALIZED_SUPPLIER, variables: currentQueryArgs }] },
  )

const handleCreateNormalized = (
  creatingNormalized: NormalizedSupplierCreateInput,
  currentQueryArgs: NormalizedSuppliersQueryArgs,
) =>
  createNormalizedSupplierMutation(
    { input: creatingNormalized },
    { refetchQueries: [{ query: NORMALIZED_SUPPLIER, variables: currentQueryArgs }] },
  )
